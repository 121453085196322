@mixin estado-styles($estado-color, $text-color) {
  background: $text-color;
  color: $estado-color !important;
  border-color: $estado-color;
  border-style: solid;
  border-width: 1px;
}

.estado-click:hover {
  @include estado-styles($default-color, #e9ecef);
  cursor: pointer;
}

.estado {
  border-radius: 25px;
  font-size: small;
  @include estado-styles($default-color, $white-color);
  min-width: 180px;
}

.estado-normalizada {
  @include estado-styles(#104794, $white-color);
}

.estado-solicitado {
  @include estado-styles($default-color, $white-color);
}

.estado-error {
  @include estado-styles($danger-color, $white-color);
}

.estado-error_enviar {
  @include estado-styles($danger-color, $white-color);
}

.estado-no_valido {
  @include estado-styles(#fb6d00, $white-color);
}

.estado-finalizado {
  @include estado-styles(#104794, $white-color);
}

.estado-pendiente_recogida {
  @include estado-styles(#a09af2, $white-color);
}

.estado-recogida_finalizada {
  @include estado-styles(#104794, $white-color);
}

.estado-en_transito {
  @include estado-styles(#fb6d00, $white-color);
}

.estado-caducado {
  @include estado-styles($danger-color, $white-color);
}

.estado-entrega_pendiente {
  @include estado-styles(#00b0f0, $white-color);
}

.estado-ubicado {
  @include estado-styles(#00b0f0, $white-color);
}

.estado-en_reparto {
  @include estado-styles(#92d050, $white-color);
}

.estado-entregado {
  @include estado-styles(#00b050, $white-color);
}

.estado-no_localizado {
  @include estado-styles($danger-color, $white-color);
}

.estado-entrega_anulada {
  @include estado-styles($danger-color, $white-color);
}

.estado-envio_anulado {
  @include estado-styles($danger-color, $white-color);
}

.estado-incidencia {
  @include estado-styles($danger-color, $white-color);
}

.estado-pendiente {
  @include estado-styles($default-color, $white-color);
}

.estado-pendiente_enviar {
  @include estado-styles($default-color, $white-color);
}

.estado-aplicada {
  @include estado-styles(#00b050, $white-color);
}

.estado-cancelada {
  @include estado-styles($danger-color, $white-color);
}

.estado-cerrada {
  @include estado-styles(#104794, $white-color);
}

.estado-cancelado {
  @include estado-styles($danger-color, $white-color);
}

.estado-facturado {
  @include estado-styles(#104794, $white-color);
}

.estado-aprobado {
  @include estado-styles(#00b050, $white-color);
}

.estado-liquidado {
  @include estado-styles(#104794, $white-color);
}

.estado-agrupado {
  @include estado-styles(#00b0f0, $white-color);
}

.estado-remesado {
  @include estado-styles(#00b050, $white-color);
}

.estado-en_curso {
  @include estado-styles(#00b050, $white-color);
}

.estado-finalizada {
  @include estado-styles(#104794, $white-color);
}

.estado-anulada {
  @include estado-styles($danger-color, $white-color);
}

.estado-enviada {
  @include estado-styles(#104794, $white-color);
}

.estado-comunicando {
  @include estado-styles(#3573fc, $white-color);
}
